import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { serialize } from 'object-to-formdata';
// utils
import axios from '../../utils/axios';
import { createDynamicURL } from '../../utils/dynamicParams';
// ----------------------------------------------------------------------
const initialState = {
    status: 'idle',
    branches: [],
    branch: null,
    meta: { currentPage: 0,
        nextPage: false,
        prevPage: false,
        totalPages: 0,
        totalRecords: 0 },
};
// ----------------------------------------------------------------------
export const getBranches = createAsyncThunk('branches/getBranches', async ({ page, rowsPerPage, orderBy = 'createdAt', order = 'desc', filterName = '', filterCity = '', filterState = '', filterCountry = '', isPaging = true, }) => {
    let data;
    const queryParams = {
        search: {
            name: filterName,
            'city.id': filterCity,
            'city.state.id': filterState,
            'city.state.country.id': filterCountry,
        },
        page: Number(page) + 1,
        perPage: rowsPerPage,
        orderBy,
        sortedBy: order,
        isPaging,
    };
    const url = createDynamicURL('/branches', queryParams);
    try {
        const response = await axios.get(url);
        data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const getBranch = createAsyncThunk('branches/getBranch', async (id) => {
    let data;
    try {
        const response = await axios.get(`/branches/${id}`);
        data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const createBranch = createAsyncThunk('branches/createBranch', async (Data, thunkAPI) => {
    let data;
    try {
        const formData = serialize({
            address: Data.address.toString(),
            branchLocations: JSON.stringify(Data.branchLocations),
            cityId: Data.cityId.toString(),
            description: Data.description.toString(),
            isHQ: Data.isHQ,
            overrideHQ: Data.overrideHQ,
            canTransfer: Data.canTransfer,
            name: Data.name.toString(),
            postalCode: Data.postalCode.toString(),
            latitude: null,
            longitude: null,
            configValues: JSON.stringify(Data.configValues),
            imageUrl: Data.imageUrl,
        });
        const response = await axios.post(`/branches`, formData);
        data = await response.data;
        if (response.status === 200) {
            thunkAPI.dispatch(getBranches({}));
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const editBranch = createAsyncThunk('branches/editBranch', async ({ id, Data }, thunkAPI) => {
    let data;
    try {
        const formData = serialize({
            address: Data?.address,
            branchLocations: JSON.stringify(Data.branchLocations),
            cityId: Data?.cityId.toString(),
            description: Data?.description.toString(),
            isHQ: Data.isHQ,
            overrideHQ: Data.overrideHQ,
            canTransfer: Data?.canTransfer,
            name: Data?.name.toString(),
            postalCode: Data.postalCode.toString(),
            latitude: null,
            longitude: null,
            configValues: JSON.stringify(Data.configValues),
            imageUrl: Data?.imageUrl,
        });
        const response = await axios.put(`/branches/${id}`, formData);
        data = await response.data;
        if (response.status === 200) {
            thunkAPI.dispatch(getBranches({}));
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const deleteBranch = createAsyncThunk('branches/deleteBranch', async ({ id, getParams }, thunkAPI) => {
    let data;
    try {
        const response = await axios.delete(`/branches/${id}`);
        data = await response.data;
        if (response.status === 200) {
            if (getParams)
                thunkAPI.dispatch(getBranches({ ...getParams }));
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const deleteManyBranches = createAsyncThunk('branches/deleteManyBranches', async ({ ids, getParams }, thunkAPI) => {
    let data;
    try {
        const response = await axios.delete(`/branches/many`, { data: { ids } });
        data = await response.data;
        if (response.status === 200) {
            thunkAPI.dispatch(getBranches({ ...getParams }));
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const deleteBranchesCheck = createAsyncThunk('branches/deleteBranchesCheck', async (ids) => {
    let data;
    try {
        const response = await axios.delete(`/branches/many/checkReserved`, { data: { ids } });
        data = await response.data;
        return data;
    }
    catch (err) {
        return err;
    }
});
export const checkHqBranches = createAsyncThunk('branches/checkHqBranches', async (id) => {
    let data;
    try {
        const response = await axios.post(`/branches/isHQ`, { id });
        data = await response.data;
        return data;
    }
    catch (err) {
        return err;
    }
});
const slice = createSlice({
    name: 'branch',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getBranches.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(getBranches.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.branches = action.payload.data;
            state.meta = action.payload.meta;
        })
            .addCase(getBranches.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(getBranch.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(getBranch.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.branch = action.payload.data;
        })
            .addCase(getBranch.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(createBranch.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(createBranch.fulfilled, (state, action) => {
            state.status = 'succeeded';
        })
            .addCase(createBranch.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(editBranch.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(editBranch.fulfilled, (state, action) => {
            state.status = 'succeeded';
        })
            .addCase(editBranch.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(deleteBranch.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(deleteBranch.fulfilled, (state, action) => {
            state.status = 'succeeded';
        })
            .addCase(deleteBranch.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(deleteManyBranches.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(deleteManyBranches.fulfilled, (state, action) => {
            state.status = 'succeeded';
        })
            .addCase(deleteManyBranches.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(checkHqBranches.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(checkHqBranches.fulfilled, (state, action) => {
            state.status = 'succeeded';
        })
            .addCase(checkHqBranches.rejected, (state, action) => {
            state.status = 'failed';
        });
    },
});
// Reducer
export const reducer = slice.reducer;
export default slice.reducer;
